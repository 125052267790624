@import "src/Sass/mixin";
@import "src/Sass/variables";

.ozLogo_wrapper {
  @include respond-below(md) {
    margin-top: 40px;
  }
}

.wrapper {
  background-image: url(../../../Assets/HomeBackground.png);
  background-size: cover;
  height: 100vh;
  min-height: 750px;
  padding-left: 134px;
  padding-right: 134px;
  padding-top: 50px;
  position: relative;
  @include respond-below(md) {
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 10px;
    height: fit-content;
  }
}

.container {
  margin-top: 126px;
  width: 65rem;
  @include respond-below(md) {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-right: 50px;
    padding-left: 50px;
  }
}


.button {
  transition: all 0.2s;
  display: inline-block;
  padding: 20px;
  background-color: #FFB600;
  margin-top: 60px;
  border-radius: 2px;
  @include respond-below(lg) {
    padding: 3rem 6rem 3rem 6rem;
  }
}

.button:hover {
  cursor: pointer;
  transform: translateY(-2px);
}

.button:active {
  cursor: pointer;
  transform: translateY(0px);
}

.button_label {
  font-family: 'Open Sans', sans-serif;
  color: #FFF;
  font-size: 1.6rem;
  font-weight: 600;
  text-transform: uppercase;
  @include respond-below(md) {
    font-size: 2.4em;
  }
}

.separator {
  position: relative;
  width: 100%;
  height: 1px;
  background-color: rgba(255, 255, 255, 0.8);
  margin-bottom: 29px;
  @include respond-below(md) {
    display: none;
  }
}

.incubators {
  position: absolute;
  bottom: 3.7rem;
  left: 0;
  right: 0;
  width: 100%;
  padding-right: 50px;
  padding-left: 50px;
  @include respond-below(md) {
    position: initial;
    width: 100%;
    padding-left: 0px;
    padding-right: 0px;
    margin-bottom: 50px;
    margin-top: 50px;
  }
}

.title_wrapper {
  width: 100%;
  @include respond-below(md) {
    align-items: center;
  }
}

.incubators_label {
  color: #FFF;
  font-family: $mainFont, sans-serif;
  text-transform: uppercase;
  font-size: 1.8rem;
  @include respond-below(md) {
    display: none;
  }
}

.yellow_title_wrapper {
  width: 100%;
  display: flex;
  @include respond-below(md) {
    justify-content: center;
  }
}

.title {
  display: inline-block;
  font-family: $titleFont, sans-serif;
  text-transform: uppercase;
  font-weight: 500;
  font-size: 9rem;
  color: #FFF;
  margin-right: 10px;
  @include respond-below(md) {
    text-align: center;
  }
}

.paragraph {
  margin-right: 50px;
  color: #FFF;
  font-weight: 200;
  font-size: 2.7rem;
  line-height: 142.5%;
  font-family: $mainFont, sans-serif;
  margin-top: 60px;
  @include respond-below(md) {
    margin-right: 0;
    text-align: center;
    line-height: 150%;
    font-size: 3.3rem;
  }
}

.yellow {
  color: #FFB600;
  margin-right: 20px;
}

.incubators_section {
  display: flex;
  justify-content: space-between;
  @include respond-below(md) {
    justify-content: center;
  }
}

.logo_wrapper {
  display: flex;
}

.incubator_logo {
  height: 4.2em;
}

.incubator_logo:not(:last-child) {
  margin-right: 7.8rem;
}
