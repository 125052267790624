@import "src/Sass/mixin";
@import "src/Sass/variables";

.about__qna {
  margin: 130px 0px;
  background-color: #F9F9F9;
  width: 100%;
  padding: 100px 0px;
  @include respond-below(md){
    padding: 50px 16px 0;
  }
}