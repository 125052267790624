@import "src/Sass/mixin";
@import "src/Sass/variables";

.projectDetails__numbers__number {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  @include respond-below(md) {
    margin-bottom: 24px;
  }
  @include respond-above(md) {
    min-width: 300px;
  }
}

.projectDetails__numbers__number:after {
  display: none;
  @include respond-above(md) {
    content: '';
    display: block;
    width: 100%;
    height: 80%;
    border-right: 1px solid #9d9d9d;
    position: absolute;
    top: 50%;
    right: 0;
    z-index: 1;
    transform: translateY(-50%);
  }
}

.projectDetails__numbers__number:last-child:after {
  display: none;
}

.projectDetails__numbers__number:last-child {
  border-right: none;
}

.projectDetails__numbers__number__digit {
  font-family: 'Oswald', serif;
  font-size: 12rem;
  letter-spacing: -0.07em;
  margin-bottom: 8px;
}

.projectDetails__numbers__number__subText {
  font-family: 'Roboto', serif;
  font-weight: 100;
  font-size: 2.2rem;
  max-width: 170px;
  text-align: center;
  @include respond-below(md) {
    font-size: 3.5rem;
  }
}
