
.carousel_button_container {
  width: 50px;
  height: 50px;
  position: absolute;
  top: 50%;
  z-index: 100;
  
}

.left {
  transform: translateY(-50%);
  left: 20px;
}

.right {
  transform: translateY(-50%) rotate(180deg);;
  right: 20px
}