@import "src/Sass/mixin";
@import "src/Sass/variables";

.container {
  padding-top: 16rem;
  padding-bottom: 16rem;
  align-items: center;
  justify-content: center;
}

.inner_container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.testimonial_text {
  font-family: 'Playfair Display', serif;
  font-size: 3.3rem;
  text-align: center;
  margin-bottom: 30px;
  color: black;
  line-height: 150%;
  @include respond-below(sm) {
    padding: 30px;
  }
  @include respond-above(sm) {
    max-width: 500px;
  }
  @include respond-above(md) {
    max-width: 600px;
  }
  @include respond-above(lg) {
    max-width: 800px;
  }
  @include respond-above(xl) {
    max-width: 800px;
  }
  @include respond-above(xxl) {
    max-width: 1230px;
  }
}

.testimonial_client_wrapper {
  display: flex;
  font-family: 'Roboto', sans-serif;
  font-size: 2.2rem;
}

.testimonial_client_name {
  color: rgba(0, 0, 0, 0.5);
  text-transform: capitalize;
}

.testimonial_client_company {
  color: #000000;
  text-transform: uppercase;

}
