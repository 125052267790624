@import "src/Sass/mixin";
@import "src/Sass/variables";

.projectDetails__numbers {
  box-sizing: border-box;

  @include respond-above(md) {
    padding-top: 148px;
  }
  @include respond-below(md){
    margin-top:50px;
  }
}

.projectDetails__numbers__innerWrapper {
  margin: 0 auto;
  @include respond-above(md) {
    display: flex;
    flex-direction: row;
    justify-content: center;
  }
}