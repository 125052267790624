.button {
  margin-top: 62px;
  width: 100%;
  height: 50px;
  background-color: #FFB600;
  align-items: center;
  justify-content: center;
  display: flex;
}

.button:hover{
  cursor: pointer;
}

.button_label {
  font-size: 2.5rem;
  color: white;
  font-weight: bold;
  text-transform: uppercase;
  font-family: 'Open Sans', sans-serif;
}