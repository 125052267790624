@import "src/Sass/mixin";
@import "src/Sass/variables";

.menu_container {
    display: flex;
    align-items: center;
    gap: 15px;
    -webkit-appearance: none;
    -moz-appearance: none;
    -ms-appearance: none;
    position: absolute;
    right: 40px;

    @include respond-below(md) {
        top: 58px;
    }

    @include respond-above(md) {
        top: 55px;
    }
}



.menu_link {
    text-transform: capitalize;
    text-decoration: none;
    color: white;
    font-family: $mainFont;
    font-weight: bold;
    font-size: 16px;

    &:visited {
        color: white;
    }

    &:hover {
        color: $mainColor;
    }
}