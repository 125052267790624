@import "src/Sass/mixin";
@import "src/Sass/variables";

.projectDetailCarousel {
  margin: 80px auto;
  position: relative;
  margin-bottom: 30px;
  @include respond-above(md){
    margin: 150px auto;
  }
}

.projectDetailCarousel__slide {
  margin: 0px 15px;
  @include respond-above(md){
    height: 400px;
    max-width: 1200px;
  }
}

.projectDetailCarousel__slide__image{
  height: 100%;
  object-fit: cover;
}



