@import "src/Sass/mixin";
@import "src/Sass/variables";


.container {
  width: 100%;
  overflow: hidden;
  padding: 5rem 7rem;
  @include respond-below(md) {
    padding: 10rem 7rem;
  }
  @include respond-below(xl) {
    padding: 10rem 7rem;
  }
}

.inner_container {
  display: flex;
  align-items: center;
  margin: 0 auto;
  max-width: 1200px;
  justify-content: center;

  @include respond-below(xl) {
    max-width: 600px;
  }
}

.banner_info {
  display: flex;
  flex-direction: column;
  @include respond-below(xl) {
    justify-content: center;
    align-items: center;
  }
}

.banner_logo {
  margin-bottom: 33px;
  width: 30rem;
}

.banner_title {
  font-family: $titleFont, sans-serif;
  font-weight: 500;
  text-transform: uppercase;
  font-size: 7rem;
  color: #FFF;
  margin-bottom: 50px;
  @include respond-below(xl) {
    text-align: center;
    font-size: 8.7rem;
  }
}

.banner_description {
  width: 100%;
  max-width: 500px;
  font-family: $mainFont, sans-serif;
  font-size: 2.2rem;
  line-height: 142.5%;
  color: #FFF;
  margin-bottom: 50px;
  @include respond-below(xl) {
    text-align: center;
    font-size: 3rem;
  }
  @include respond-below(xl) {
    text-align: center;
    font-size: 3.3rem;
  }
}

.banner_button {
  transition: all .2s;
  position: relative;
  background-color: rgba(255, 255, 255, 0.0);
  border-style: solid;
  border-color: #FFF;
  border-width: 1px;
  width: fit-content;
  color: #FFF;
  z-index: 1;
  padding: 3rem 6rem 3rem 6rem;
  font-family: 'Open Sans', sans-serif;
  font-size: 2.4rem;
  font-weight: 600;
  text-transform: uppercase;
  overflow: hidden;
  @include respond-below(lg) {
      background-color: white;
      border-width: 1px;
      width: fit-content;
      color: black;
  }
}

.banner_button:focus {
  outline: 0;
}

.banner_button:before {
  transition: all .2s;
  content: "";
  position: absolute;
  background: #FFF;
  bottom: 0;
  left: 0;
  right: 100%;
  top: 0;
  z-index: -1;
}

.banner_illustration {
  @include respond-below(xl) {
    display: none;
  }
}

.banner_illustration_image {
  max-height: 700px;
  @include respond-below(xxl) {
    max-height: 500px;
  }
}

@media (hover: hover) {
  .banner_button:hover {
    cursor: pointer;
    color: black;
  }

  .banner_button:hover:before {
    right: 0;
  }

}