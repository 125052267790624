@import "src/Sass/mixin";
@import "src/Sass/variables";

.QnASection {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.QnASection__description {
  text-align: center;
  max-width: 380px;
  font-family: $mainFont;
  font-style: normal;
  font-weight: 100;
  font-size: clamp(14px, 2vw, 18px);
  margin-bottom: 50px;
  @include respond-above(md) {
    line-height: 140%;
    max-width: 500px;
  }

  @include respond-below(md) {
    line-height: 170%;
  }
}

.QnASection__title {
  color: black;
  font-weight: bold;
  font-size: clamp(14px, 4vw, 30px);
  font-family: $mainFont;
  margin-bottom: 14px;
  @include respond-below(md) {
    font-size: 16px;
  }
}




