.logo {
  width: 223px;
}

.mini_logo {
  width: 42px;
}

.logo__wrapper {
  display: flex;
  align-items: center;
}

.mini_logo__wrapper {
  display: flex;
  align-items: center;
  padding-left: 10px;
}