@import "src/Sass/mixin";
@import "src/Sass/variables";

.languageWidget {
    -webkit-appearance: none;
    -moz-appearance: none;
    -ms-appearance: none;
    appearance: none;
    outline: 0;
    box-shadow: none;
    border: 0 !important;
    background: rgba(0, 0, 0, 0.1) none;
    color:white;
    padding: 4px;
    border-radius: 4px;
    font-weight: 600;
    text-transform: capitalize;
    font-family: $mainFont;
    font-size: 16px;
}

.languageWidget option{
   color: black;
}