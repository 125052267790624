@import "src/Sass/mixin";
@import "src/Sass/variables";

.projectDetailsNav {
  padding-bottom: 112px;
  width: 100%;
  max-width: 1500px;
  margin: 24px auto 0;
  position: relative;
}

.projectDetailsNav__wrapper__logo {
  height: 32px;
  max-width: 200px;
  position: absolute;
  left: 24px;
  margin-top: 48px;
  @include respond-below(md){
    display: none;
  }
}

.projectDetailsNav__logo {
  height: 100%;
}