@import "src/Sass/mixin";
@import "src/Sass/variables";

.projectDetailsHeader {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 112px;
}

.projectDetailsHeader__wrapper__logo {
  margin-top: 64px;
  height: 40px;
}

.projectDetailsHeader__logo {
  height: 100%;
}

.projectDetailsHeader__quote {
  margin-top: 80px;
  font-family: $titleFont;
  font-weight: 500;
  font-size: clamp(40px, 5vw, 80px);
  line-height: 111%;
  text-align: center;
  letter-spacing: -0.07em;
  color: #FFFFFF;
  max-width: 200px;
  @include respond-above(md) {
    max-width: 562px;
  }
}

.projectDetailsHeader__wrapper__cover {
  max-width: 1000px;
  display: flex;
  justify-content: center;
}

.projectDetailsHeader__cover {
  width: 100%;
}

.projectDetailsHeader__description {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.projectDetailsHeader__description__title {
  margin-top: 64px;
  max-width: 250px;
  font-family: $titleFont;
  font-weight: 500;
  font-size: clamp(30px, 3vw, 50px);
  line-height: 111%;
  letter-spacing: -0.07em;
  text-transform: uppercase;
  color: #FFFFFF;
  text-align: center;
  @include respond-above(md) {
    max-width: 562px;
  }
}

.projectDetailsHeader__description__text {
  max-width: 380px;
  margin-top: 32px;
  font-family: $mainFont;
  font-style: normal;
  font-weight: 100;
  font-size: 3rem;
  line-height: 140%;
  text-align: center;
  color: #FFFFFF;
  @include respond-above(md) {
    max-width: 500px;
  }
  @include respond-below(md) {
    font-size: 3.5rem;
  }
  @include respond-below(sm) {
    max-width: 300px;
  }
}