.title{
  font-family: 'Oswald', sans-serif;
  font-size: 40px;
  text-transform: uppercase;
  margin-bottom: 45px;
  text-align: center;
}

.thankyou__internal_container{
  display: flex;
  flex-direction: column;
  height: 100%;
  align-items: center;
  justify-content: center;
  max-width: 600px;
  margin:0 auto;
}

.thankyou__button_container{
  width: 300px
}