@import "src/Sass/mixin";
@import "src/Sass/variables";

.sectionTitleWithDescription {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.sectionTitleWithDescription__description {
  font-family: $mainFont;
  font-style: normal;
  font-weight: 100;
  font-size: 3rem;
  line-height: 160%;
  text-align: center;
  @include respond-above(sm) {
    max-width: 500px;
    font-size: 22px;
    line-height: 140%;
  }
  @include respond-below(sm) {
    max-width: 300px;
  }
}





