@import "src/Sass/variables";
@import "src/Sass/mixin";

.container {
  width: 100%;
  padding-bottom: 112px;
  display: flex;
  justify-content: center;
  @include respond-below(sm) {
    padding: 16px;
  }
}

.inside_container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 570px;
  @include respond-below(sm) {
    width: 300px;
  }
  @include respond-below(md) {
    width: 450px;
  }
}

.contact_header {
  font-family: 'Oswald', sans-serif;
  font-size: 40px;
  text-transform: uppercase;
  margin-bottom: 45px;
}

.input_row {
  display: grid;
  grid-template-columns: 1fr 1fr;
  width: 100%;
  column-gap: 2rem;
  @include respond-below(sm) {
    grid-template-columns: 1fr;
  }
  @include respond-below(md) {
    grid-template-columns: 1fr;
  }
}

.one_row_item_container {
  display: grid;
  grid-template-columns: 1fr;
  width: 100%;
}

.budget_header {
  align-self: flex-start;
  text-transform: uppercase;
  font-family: 'Oswald', sans-serif;
  font-size: 30px;
  margin-bottom: 23px;
  margin-top: 30px;
}

.option_text {
  font-family: 'Roboto', sans-serif;
  font-size: 16px;
  justify-self: center;
  margin-top: 3.5px;
  color: rgba(0, 0, 0, 0.7)
}

.contact_textarea {
  border-style: solid;
  border-width: 0.5px;
  border-color: rgba(0, 0, 0, 0.5);
  padding-left: 15px;
  font-family: 'Roboto', sans-serif;
  font-size: 16px;
  height: 270px;
  padding-top: 20px;
}

.contact_textarea::placeholder {
  font-family: 'Roboto', sans-serif;
  font-size: 16px;
}


.contact_submit_title {
  font-family: $mainFont;
  font-size: 16px;
  width: 100%;
  text-align: center;
  margin-top: 12px;
}

.error {
  color: red;
}

.success {
  color: green
}
