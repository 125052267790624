@import "src/Sass/mixin";
@import "src/Sass/variables";

.projectDetailsNav__backLink {
  text-decoration: none;
  position: absolute;
  margin-left: -10px;
  margin-top: 58px;
  left: 50%;
  transform: translatex(-50%);
}

.projectDetailsNav__backLink__text {
  text-transform: uppercase;
  font-family: $titleFont;
  font-style: normal;
  font-weight: 500;
  font-size: 25px;
  letter-spacing: -0.07em;
  color: #FFFFFF;
  margin-left: 4px;
}