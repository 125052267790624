@import "src/Sass/mixin";
@import "src/Sass/variables";

.sectionTitle {
  margin-top: 128px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 40px;
}

.sectionTitle__text {
  font-size: 5rem;
  font-family: $titleFont;
  letter-spacing: -0.07em;
  text-transform: uppercase;
  margin-bottom: 40px;
}

.sectionTitle__bottomBar {
  width: 40px;
  height: 4px;
  background: #000;
  border: none;
}






