@import "/src/Sass/variables";

.input{
  height: 60px;
  border-style: solid;
  border-width: 0.5px;
  border-color: rgba(0,0,0,0.5);
  padding-left: 15px;
  font-family: $mainFont, sans-serif;
  margin-bottom: 24px;
  font-size: 16px;

}

.input_error{
  border-color: red;
  margin-bottom: 12px;
}

.input_innerwrapper{
 display: flex;
 flex-direction: column;
}

.input_error{
  font-family: 'Roboto', sans-serif;
  font-size: 16px;
  color: red;
  padding-left:15px;
  margin-bottom: 12px;
}