@import "src/Sass/mixin";
@import "src/Sass/variables";

.section_container{
  margin-top:100px;
  background-color: hsl(0, 0%, 96%);
  padding: 50px 200px;
  display: flex;
  @include respond-below(lg){
    padding: 50px 10px;
  }

}
.section_inner{
  display: flex;
  align-items: center;
  justify-content: space-around;
  max-width: 1500px;
  margin: 0 auto;
  @include respond-below(md){
    flex-direction: column;
  }
}
.section_device{
  width: 20%;
  min-width: 200px;
  @include respond-below(md){
    margin-bottom: 50px;
  }
}